import React from "react"
import ColorshelfPage from "../components/colorlist/colorshelfpage"

const colorlist = require('../content/custom/colorlist/shelf1.json');

const ColorlibraryShelf1Page = () => (
	<ColorshelfPage
			activePath={"colorlibrary"}
			colorList={colorlist}
		/>
)

export default ColorlibraryShelf1Page
